<template>
  <p class="error-contact text-body">
    Want to get in touch?
    <span
      v-focusable
      :id="$options.consts.ACTIVATOR_ID.CONTACT_US_WIDGET_ERROR_PAGE"
      class="error-contact__link"
      @click="openContactUsWidget"
      >Contact Us</span
    >
    and help make Finance Magnates even better by letting us know about the bad
    link.
  </p>
</template>

<script>
import { WIDGET } from 'shared/AContactWidget'
import { OPEN_LAYOUT_WIDGET_BUS_NAME } from 'shared/AContactWidget/enums'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'

export default {
  name: 'AErrorContact',
  consts: {
    ACTIVATOR_ID
  },
  methods: {
    openContactUsWidget() {
      this.$bus.$emit(OPEN_LAYOUT_WIDGET_BUS_NAME, {
        name: WIDGET.CONTACT_US,
        activatorSelector:
          ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[
            ACTIVATOR_ID.CONTACT_US_WIDGET_ERROR_PAGE
          ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.error-contact {
  @include mobile {
    text-align: center;
  }

  &__link {
    color: $c--main;
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
  }
}
</style>

<template>
  <div class="error-details">
    <a-render-html
      v-if="message"
      :html="message"
      class="error-details__info text-body"
    />

    <div class="error-details__stay-connected">
      <div class="error-details__stay-connected-info text-body">
        <p>
          Stay Connected!
        </p>
        <p>
          Whatever you were looking for, we have all the answers. Get our market
          news wrap, education goodies, and special offers for a better-informed
          trading experience.
        </p>
      </div>

      <a-button
        :id="$options.consts.ACTIVATOR_ID.NEWSLETTER_WIDGET_ERROR_PAGE"
        class="error-details__button"
        @click="openNewsletterWidget"
      >
        SUBSCRIBE TO OUR NEWSLETTER
      </a-button>
    </div>

    <a-contact class="error__block_contact" />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { WIDGET } from 'shared/AContactWidget'
import { OPEN_LAYOUT_WIDGET_BUS_NAME } from 'shared/AContactWidget/enums'
import AContact from '@/components/_layout/Error/AContact'
import ARenderHtml from 'shared/ARenderHtml'
import {
  ACTIVATOR_ID,
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID
} from 'enums/activator-id'

export default {
  name: 'AErrorDetails',
  components: { AContact, ARenderHtml },
  props: {
    message: propValidator([PROP_TYPES.STRING], false)
  },
  consts: {
    ACTIVATOR_ID
  },
  methods: {
    openNewsletterWidget() {
      this.$bus.$emit(OPEN_LAYOUT_WIDGET_BUS_NAME, {
        name: WIDGET.NEWSLETTER,
        activatorSelector:
          ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[
            ACTIVATOR_ID.NEWSLETTER_WIDGET_ERROR_PAGE
          ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.error-details {
  &__info {
    margin: 0 auto 30px;
    text-align: center;
    width: percentage(560 / 600);

    /deep/ a {
      color: $c--main;
    }

    @include mobile {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &__stay-connected {
    padding: 20px 20px 30px;
    width: 100%;
    border: 1px dashed $c--gray-secondary;

    @include mobile {
      padding: 30px 10px 20px;
    }
  }

  &__stay-connected-info {
    text-align: center;
    margin-bottom: 25px;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .error-details__button {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
  }

  .error__block_contact {
    margin: 60px 0;
    width: 100%;
    text-align: center;

    @include mobile {
      margin: 20px 0 5px 0;
    }
  }
}
</style>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { pathOr } from 'ramda'
import { isElementPureText } from '@/utils/helpers/processHtml/prettifyHtml'
import { decodeHTML } from 'entities'
import { HTML_TAG } from '@/utils/helpers/processHtml/enums'
import { parseHtml } from '@/utils/helpers/processHtml/parseHtml'

export default {
  name: 'ARenderHtml',
  props: {
    html: propValidator([PROP_TYPES.STRING], false),
    containerTag: propValidator([PROP_TYPES.STRING], false, HTML_TAG.DIV)
  },
  methods: {
    createElementAttributes(element) {
      return {
        props: element.props,
        attrs: element.attrs,
        class: element.class,
        style: pathOr(null, ['attrs', 'style'], element),
        on: element.on
      }
    },
    isChildrenExist(element) {
      return element.content && !!element.content.length
    },
    parseHtml() {
      if (!this.html) return this.html

      return parseHtml(this.html.replace(/&nbsp;/gi, ' '))
    },
    isNoElement(element) {
      return element === undefined
    },
    createVueElement(h, vNode) {
      const targetNode = vNode || this.parseHtml()
      if (!targetNode || targetNode.length === 0) return []

      const children = []

      targetNode.forEach(element => {
        if (this.isNoElement(element)) return

        if (isElementPureText(element)) {
          children.push(decodeHTML(element))
          return
        }

        const attributes = this.createElementAttributes(element)

        const nestedChildren = this.isChildrenExist(element)
          ? this.createVueElement(h, element.content)
          : null

        children.push(h(element.tag, attributes, nestedChildren))
      })
      return children
    }
  },
  render(h) {
    const html = this.createVueElement(h)

    return h(
      this.containerTag,
      {
        class: 'render-html__wrapper html-content__general-styles',
        on: this.$listeners
      },
      [html, h(HTML_TAG.SPAN, { class: 'clearfix' })]
    )
  }
}
</script>

<style lang="scss">
@import '~assets/scss/local/styles/_html.scss';
</style>
